<template>
  <div id="app" v-bind:class="currentTheme">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />

    <div class="context-title">
      <h1>Configuración</h1>
    </div>

    <v-row align="center" justify="center" class="context-card">
      <v-expansion-panels popout v-model="panel" focusable :dark="isDark">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Apariencia</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <div class="context-title">
              <h5>Personalizar apariencia</h5>
              <p>Selecciona el color del tema para que sea aplicado.</p>
            </div>

            <div class="theme-options">
              <div
                id="theme-white"
                v-bind="{ active: currentTheme === 'theme-white' }"
                v-on:click="swicthTheme('theme-white')"
              ></div>
              <div
                id="theme-blue"
                v-bind="{ active: currentTheme === 'theme-blue' }"
                v-on:click="swicthTheme('theme-blue')"
              ></div>
              <div
                id="theme-orange"
                v-bind="{ active: currentTheme === 'theme-orange' }"
                v-on:click="swicthTheme('theme-orange')"
              ></div>
              <div
                id="theme-purple"
                v-bind="{ active: currentTheme === 'theme-purple' }"
                v-on:click="swicthTheme('theme-purple')"
              ></div>
              <div
                id="theme-green"
                v-bind="{ active: currentTheme === 'theme-green' }"
                v-on:click="swicthTheme('theme-green')"
              ></div>
              <div
                id="theme-black"
                v-bind="{ active: currentTheme === 'theme-black' }"
                v-on:click="swicthTheme('theme-black')"
              ></div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          class="mt-3"
          v-show="role == 'Administrator' || role == 'Manager'"
        >
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Asistentes</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <UserAssistant />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          class="mt-3"
          v-show="role == 'Administrator' || role == 'Manager'"
        >
          <v-expansion-panel-header class="text-h6" color="context-header"
            >Bloqueo de horarios</v-expansion-panel-header
          >
          <v-expansion-panel-content color="context-card-color">
            <configure-disabled-date />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <div class="context-card mt-5" v-show="false">
      <div class="context-title">
        <h3>Depurar base de datos</h3>
        <p>
          Elimina registros antiguos de la base de datos, selecciona los
          parámetros necesarios.
        </p>

        <div class="mb-3">
          <v-checkbox
            v-model="IsCleanEnabledBD"
            color="indigo"
            label="Eliminar datos antiguos"
            hide-details
          ></v-checkbox>
        </div>
        <div v-if="IsCleanEnabledBD">
          <v-flex xs11 md2>
            <v-text-field
              class="custom-placeholer-color"
              v-model="countYearToDelete"
              type="number"
              label="Años de respaldo"
              outlined
              dense
            ></v-text-field>
          </v-flex>
          <div>
            <h6>Se eliminaran todos los registros con fecha mayor</h6>
          </div>
          <v-card-actions>
            <v-btn class="context-button-style"> Eliminar registros </v-btn>
          </v-card-actions>
        </div>
        <v-divider class="mb-5"></v-divider>
        <h3>Exportar base de datos</h3>
        <p>Exporta la base de datos en formato Excel.</p>
        <v-file-input
          class="custom-placeholer-color"
          label="File input"
        ></v-file-input>
      </div>
      <div class="mb-1 mt-5">
        <v-card-actions>
          <v-btn class="context-button-style"> Exportar </v-btn>
        </v-card-actions>
      </div>
    </div>

    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import LoadingProcess from "../components/LoadingProcess.vue";
import UserAssistant from "../components/Settings/UserAssistant.vue";
import ConfigureDisabledDate from "../components/Settings/ConfigureDisabledDate.vue";

export default {
  components: { LoadingProcess, UserAssistant, ConfigureDisabledDate },
  data() {
    return {
      snackbar: false,
      messageSnackbar: "",
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
      countYearToDelete: 1,
      IsCleanEnabledBD: false,
      currentTheme: localStorage.getItem("theme-color"),
      role: localStorage.getItem("role"),
      panel: 0,
      isDark: false,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getUsers;
      },
      deep: true,
    },
  },
  computed: {
    //...mapGetters({
    // map `this.user` to `this.$store.getters.user`
    //  user: "user",
    // }),
    // formIsValid() {
    //   return this.form.specialty && this.form.phone;
    // },
  },

  async created() {
    this.loadingTitle = "Cargando la configuración...";
    this.isMobile = helperServices.MobileService.isMobile();
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },

  methods: {
    initialize() {},

    swicthTheme(theme) {
      //this.userSelected = this.$store.getters.user.data;
      this.InsertUserSetting(theme);
    },

    InsertUserSetting(theme) {
      this.loadingShow = true;
      //this.userSelected = this.$store.getters.user.data;

      let item = {
        id: 0,
        theme: theme,
        userUID: null, //this.userSelected.userUID,
      };
      //console.log(item);
      //Se inserta un nuevo registro
      apiServices.SettingService.InsertSetting(item)
        .then(() => {
          localStorage.setItem("theme-color", theme);
          this.currentTheme = localStorage.getItem("theme-color");
          //window.location.reload();
          this.loadingShow = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    getUsers() {
      if (this.role != "Consumer") {
        const { page, itemsPerPage } = this.options;

        // sortBy,sortDesc
        var filter = {
          searchText: this.search,
          userUID: null,
          PageSize: itemsPerPage,
          PageNumber: page,
        };
        //this.itemsPerPage = filter.PageSize;
        //this.page = filter.page;
        this.loadingShow = true;
        apiServices.userService
          .GetConsumerUsersPagination(filter)
          .then((resp) => {
            this.users = resp.data.data;
            this.totalUsers = resp.data.meta.totalCount;
            //  this.currentPage = resp.data.meta.currentPage;
            this.loadingShow = false;
          })
          .catch((error) => {
            this.loadingShow = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    addItem() {
      this.headerTitle = "Agregar usuario asistente";
      this.buttonTitle = "Guardar";
      this.userSelected = Object.assign({}, this.defaultForm);
      this.userSelected.id = -1;
      this.AddUserDialog = true;
    },
    editItem(item) {
      this.headerTitle = "Editar usuario asistente";
      this.buttonTitle = "Actualizar";
      this.userSelected = item;
      this.AddUserDialog = true;
    },
    deleteItem(item) {
      this.userSelected = item;

      //this.dialogDelete = true;
      this.$fire({
        title: "Desea eliminar el usuario asistente?",
        text: item.userName,
        type: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Usuario asistente eliminado correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },
    deleteItemConfirm() {
      if (this.userSelected) {
        console.log("Id Usuario:" + this.userSelected);
        apiServices.userService
          .DeleteConsumerUser(this.userSelected.id)
          .then(() => {
            this.search = "";
            this.getUsers();
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeWinEditUser(result) {
      if (result.tipo == "cerrar") {
        this.AddUserDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddUserDialog = result.isVisible;
        //this.snackbar = true;
        // this.messageSnackbar = "Paciente creado correctamente.";

        this.$fire({
          position: "top-end",
          title: "Usuario asistente creado correctamente",
          // text: "Operación exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getUsers();
        //  this.options="getPatients";
        // this.totalPatients += 1;
        // this.showSnackbar = true;
      } else if (result.tipo == "editar") {
        this.AddUserDialog = result.isVisible;
        // this.snackbar = true;
        // this.messageSnackbar = "Paciente modificado correctamente.";
        this.getUsers();
        //https://www.npmjs.com/package/vue-simple-alert
        this.$fire({
          position: "top-end",
          title: "Usuario asistente modificado correctamente",
          // text: "Edición exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          //grow:'row',
          //width: 300,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });

        //this.options = "getPatients";
        //console.log(result)
        //const indexOfItemInArray = this.patients.findIndex(q => q.id === result.item.id);
        //this.patients.splice(indexOfItemInArray, 1, result.item);
        // this.options.handler.getPatients;
        // this.showSnackbar = true;
      }
    },
  },
};
</script>
<style></style>
