import Axios from 'axios'
import patientService from './PatientService'
import scheduleService from './ScheduleService'
import ScheduleDisabledService from './ScheduleDisabledService'
import userService from './UserService'
import LoginService from './LoginService'
import DashboardService from './DashboardService'
import PasswordRecoveryService from './PasswordRecoveryService'
import MercadoPagoService from './MercadoPagoService'
import NoteService from './NoteService'
import PaypalInformationService from './PaypalInformationService'
import SettingService from './SettingService'


//import store from '../store/index'

//localhost
//let apiUrl = 'https://localhost:44342/api/'
//backend DEV
//let apiUrl = 'https://levejo-001-site1.gtempurl.com/api/'
//backend PROD
let apiUrl = 'https://levejo-001-site3.gtempurl.com/api/'

//let token = 'Bearer ' + store.getters.user.token.token
Axios.interceptors.response.use(
    (response) => {
      if (response.status === 401 || response.status === 404) {
        //add your code
        alert("You are not authorized");
        
        this.$store.dispatch("fetchUser", null);
        this.$router.push("/home").catch(() => {});
        console.log("You are not authorized")
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.data) {
        //add your code
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

// Axios Configuration
Axios.defaults.headers.common.Accept = 'application/json'
//Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
//Axios.defaults.headers.common['Access-Control-Request-Method'] = '*'

//Axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token 

export default {
       
    patientService: new patientService(Axios, apiUrl),
    scheduleService: new scheduleService(Axios, apiUrl),
    ScheduleDisabledService: new ScheduleDisabledService(Axios, apiUrl),
    userService: new userService(Axios,apiUrl),
    LoginService: new LoginService(Axios,apiUrl),
    DashboardService: new DashboardService(Axios,apiUrl),
    PasswordRecoveryService: new PasswordRecoveryService(Axios,apiUrl),
    MercadoPagoService: new MercadoPagoService(Axios,apiUrl),
    NoteService: new NoteService(Axios, apiUrl),
    PaypalInformationService: new PaypalInformationService(Axios, apiUrl),
    SettingService: new SettingService(Axios, apiUrl)
}