<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2">
        <h3>{{ headerTitle }}</h3>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrar', '')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="450px"
        color="transparent"
      >
        <v-container style="height: 520px">
          <div>
            <v-form ref="patientSelected" @submit.prevent="submit">
              <v-container>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      class="mt-5 custom-placeholer-color"
                      v-model="patientSelected.name"
                      :rules="rules.name"
                      label="Nombre"
                      required
                      outlined
                      dense
                      placeholder="Introduce el nombre del paciente"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      class="custom-placeholer-color"
                      v-model="patientSelected.lastName"
                      :rules="rules.lastName"
                      label="Apellidos"
                      required
                      outlined
                      dense
                      placeholder="Introduce los apellidos del paciente"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row wrap justify-space-around>
                  <v-flex xs11 md12>
                    <v-text-field
                      type="date"
                      label="Fecha de nacimiento"
                      v-model="patientSelected.birthDate"
                      :rules="rules.birthDate"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>-->
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-select
                      :items="['Masculino', 'Femenino']"
                      v-model="patientSelected.sex"
                      :rules="rules.sex"
                      label="Sexo"
                      required
                      outlined
                      dense
                      class="custom-placeholer-color"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row wrap justify-space-around>
                  <v-flex xs11 md12>
                    <v-text-field
                      v-model="patientSelected.bloodType"
                      :rules="rules.bloodType"
                      color="blue darken-2"
                      label="Grupo Sanguíneo"
                      required
                      outlined
                      dense
                      placeholder="Introduce el grupo sanguíneo del paciente"
                      append-icon="bloodtype"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs11 md12>
                    <v-textarea
                      v-model="patientSelected.address"
                      color="teal"
                      :rules="rules.address"
                      required
                      outlined
                      dense
                      placeholder="Introduce la dirección del paciente"
                      label="Dirección"
                      rows="3"
                      append-icon="home"
                    >
                    </v-textarea>
                  </v-flex>
                </v-layout>-->
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="patientSelected.phone"
                      :rules="rules.phone"
                      class="custom-placeholer-color"
                      label="Teléfono"
                      required
                      outlined
                      dense
                      append-icon="phone"
                      @input="enforcePhoneFormat()"
                      maxlength="16"
                      placeholder="(123) 456-7890"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <!-- <v-layout row wrap justify-space-around>
                  <v-flex xs11 md12>
                    <v-text-field
                      v-model="patientSelected.email"
                      :rules="rules.email"
                      color="blue darken-2"
                      label="Email"
                      required
                      outlined
                      dense
                      placeholder="Introduce el email del paciente"
                      append-icon="mail"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs11 md12>
                    <v-text-field
                      v-model="patientSelected.rfc"
                      color="blue darken-2"
                      label="RFC"
                      outlined
                      dense
                      placeholder="Introduce el RFC del paciente"
                      append-icon="notes"
                    ></v-text-field>
                  </v-flex>
                </v-layout>-->
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-select
                      :items="['Particular', 'Seguros', 'Pemex']"
                      v-model="patientSelected.typeAppointment"
                      :rules="rules.typeAppointment"
                      label="Tipo Paciente"
                      required
                      outlined
                      dense
                      class="custom-placeholer-color"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  justify-space-around
                  v-if="patientSelected.typeAppointment === 'Pemex'"
                >
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="patientSelected.numberSheet"
                      class="custom-placeholer-color"
                      label="Ficha"
                      outlined
                      dense
                      placeholder="Introduce el número de ficha del paciente"
                      append-icon="tag"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-space-around>
                  <v-flex xs12 md12>
                    <v-select
                      :items="['Primera Vez', 'Subsecuente']"
                      v-model="patientSelected.patientState"
                      :rules="rules.patientState"
                      label="Estado"
                      required
                      outlined
                      dense
                      class="custom-placeholer-color"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-card-actions>
                <!-- <v-btn text @click.stop="show = false"> Cancelar </v-btn>-->
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!formIsValid"
                  :loading="isLoading"
                  type="submit"
                  class="context-button-style"
                  rounded
                  small
                >
                  <v-icon left> save </v-icon>
                  {{ buttonTitle }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </v-container>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      name: "",
      lastName: "",
      sex: "",
      bloodType: "",
      birthDate: new Date().toISOString().substr(0, 10),
      address: "",
      phone: "",
      email: "",
      rfc: "",
      typeAppointment: "",
      patientState: "",
      numberSheet: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        name: [(val) => (val || "").length > 0 || "Nombre es requerido."],
        lastName: [
          (val) => (val || "").length > 0 || "Apellidos son requerido.",
        ],
        //  sex: [(val) => (val || "").length > 0 || "Sexo es requerido."],
        // bloodType: [
        //   (val) => (val || "").length > 0 || "Grupo sanguíneo es requerido.",
        // ],
        //  birthDate: [
        //    (val) =>
        //     (val || "").length > 0 || "Fecha de nacimiento es requerida.",
        // ],
        //  address: [(val) => (val || "").length > 0 || "Dirección es requerida."],
        phone: [(val) => (val || "").length > 0 || "Teléfono requerido."],
        email: [
          // (val) => (val || "").length > 0 || "Email es requerido.",
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email invalido.";
          },
        ],
        //   typeAppointment: [
        //     (val) => (val || "").length > 0 || "Tipo Cita es requerida.",
        //   ],
        //    patientState: [
        //      (val) => (val || "").length > 0 || "Estado es requerido.",
        //    ],
      },
      snackbar: false,
      defaultForm,
      isLoading: false,
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  props: {
    patientSelected: null,
    headerTitle: String,
    buttonTitle: String,
  },
  computed: {
    formIsValid() {
      return (
        this.patientSelected.name &&
        this.patientSelected.lastName &&
        //this.patientSelected.sex &&
        //this.patientSelected.bloodType &&
        //this.patientSelected.birthDate &&
        //this.patientSelected.address &&
        this.patientSelected.phone &&
        //this.patientSelected.email &&
        this.patientSelected.typeAppointment
        //this.patientSelected.patientState
      );
    },
  },
  mounted() {
    //  this.isVisible = this.props.patientSelected.isVisible;
    // console.log(this.props.patientSelected);
    // this.patientSelected = this.props.patientSelected;
  },

  methods: {
    resetForm() {
      this.patientSelected = Object.assign({}, this.defaultForm);
      this.$refs.patientSelected.reset();
    },

    submit() {
      this.isLoading = true;
      let item = {
        id: 0,
        name: this.patientSelected.name,
        lastName: this.patientSelected.lastName,
        fullName:
          this.patientSelected.name + " " + this.patientSelected.lastName,
        sex:
          this.patientSelected.sex != undefined ? this.patientSelected.sex : "",
        bloodType:
          this.patientSelected.bloodType != undefined
            ? this.patientSelected.bloodType
            : "",
        birthDate:
          this.patientSelected.birthDate != undefined
            ? this.patientSelected.birthDate
            : "",
        address:
          this.patientSelected.address != undefined
            ? this.patientSelected.address
            : "",
        phone: this.patientSelected.phone,
        email:
          this.patientSelected.email != undefined
            ? this.patientSelected.email
            : "",
        rfc:
          this.patientSelected.rfc != undefined ? this.patientSelected.rfc : "",
        typeAppointment:
          this.patientSelected.typeAppointment != undefined
            ? this.patientSelected.typeAppointment
            : "",
        patientState:
          this.patientSelected.patientState != undefined
            ? this.patientSelected.patientState
            : "",
        numberSheet:
          this.patientSelected.numberSheet != undefined
            ? this.patientSelected.numberSheet
            : "",
        //userUID: this.useruid,
      };
      //console.log(item);
      if (this.patientSelected.id == -1) {
        //Se inserta un nuevo registro
        apiServices.patientService
          .InsertPatient(item)
          .then(() => {
            //this.resetForm();
            // this.$router.push({
            // name: "Patients",
            //  params: { isNewPatient: true },
            // });
            // console.log(item);
            this.closeWindow("nuevo", "");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });

        // item["fechaCreacion"] = new Date();

        //db.collection("patients").add(item);
      } else {
        item["id"] = this.patientSelected.id;
        apiServices.patientService
          .UpdatePatient(item)
          .then(() => {
            //this.resetForm();
            // this.$router.push({
            //   name: "Patients",
            //   params: { isNewPatient: false },
            // });
            this.closeWindow("editar", item);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
        //item["fechaActualizacion"] = new Date();
        //  db.collection("patients")
        //    .doc(this.id)
        //    .update(item);
      }
    },
    closeWindow(value, item) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditPatient", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
          item: item,
        };
        this.$emit("closeEditPatient", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditPatient", result);
      }
      //  this.resetForm();
    },
    enforcePhoneFormat() {
      let x = this.patientSelected.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.patientSelected.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
  created() {
    // let itemUpdate = this.$route.params.patient; //recupero el objeto del router
    //this.useruid = this.$store.getters.user.data.uid; //recupero el userUID
    /* if (this.patientSelected != undefined) {
      // console.log("recupero actualizar " + itemUpdate);
      //editar
      this.headerTitle = "Editar Paciente";
      this.buttonTitle = "Actualizar";
      this.id = this.patientSelected.id;
      this.idPatient = this.patientSelected.id;
      this.form.name = this.patientSelected.name;
      this.form.lastName = this.patientSelected.lastName;
      this.form.birthDate = this.patientSelected.birthDate;
      this.form.address = this.patientSelected.address;
      this.form.phone = this.patientSelected.phone;
      this.form.email = this.patientSelected.email;
      this.form.rfc = this.patientSelected.rfc;
      this.form.typeAppointment = this.patientSelected.typeAppointment;
      this.form.patientState = this.patientSelected.patientState;
    } else {
      // let uid = this.$route.params.useruid; //recupero el objeto del router
      //console.log("recupero guardar " + uid);
      //if (uid != undefined) this.useruid = uid;
      this.headerTitle = "Agregar Paciente";
      this.buttonTitle = "Guardar";
    }*/
  },
};
</script>
