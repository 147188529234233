<template>
  <div id="app" v-bind:class="currentTheme">
    <v-toolbar flat class="context-toolbar">
      <v-toolbar-title class="context-title2" v-if="!isMobile">
        <h3>{{ titleEvent }}</h3>
      </v-toolbar-title>
      <v-toolbar-title class="context-title2" v-else>
        <h5>{{ titleEvent }}</h5>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon bottom right offset-y @click="closeWindow('cerrarEditEvent')">
        <v-icon class="context-icon-color">close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="context-card2">
      <v-form ref="form" @submit.prevent="submit">
        <div class="pa-4">
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Selecciona fecha de bloqueo"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    class="custom-placeholer-color"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  no-title
                  :min="new Date().toISOString().substr(0, 10)"
                  @input="menu2 = false"
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-text-field
                class="custom-placeholer-color"
                v-model="form.description"
                :rules="rules.description"
                label="Descripción"
                required
                outlined
                dense
                placeholder="Introduce una descripción"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md12>
              <v-checkbox
                v-model="form.fullDay"
                label="Bloquear día completo"
                @click="AsignaHorario()"
              ></v-checkbox>
            </v-flex>
          </v-layout>

          <v-layout
            row
            wrap
            justify-space-around
            v-if="isMobile"
            v-show="!form.fullDay"
          >
            <v-flex xs6 md6>
              <v-dialog
                ref="dialog"
                v-model="modalHoraInicio"
                :return-value.sync="form.start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start"
                    label="Hora Inicio"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    class="custom-placeholer-color mr-3"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalHoraInicio"
                  v-model="form.start"
                  full-width
                  no-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalHoraInicio = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(form.start)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs6 md6>
              <v-menu
                ref="menu"
                v-model="modalHoraFin"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end"
                    label="Hora Fin"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    class="custom-placeholer-color"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalHoraFin"
                  v-model="form.end"
                  full-width
                  no-title
                  @click:minute="$refs.menu.save(form.end)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap justify-space-around v-else v-show="!form.fullDay">
            <v-flex xs6 md6>
              <v-dialog
                ref="dialog"
                v-model="modalHoraInicio"
                :return-value.sync="form.start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start"
                    label="Hora Inicio"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    class="custom-placeholer-color mr-3"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalHoraInicio"
                  v-model="form.start"
                  full-width
                  no-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalHoraInicio = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(form.start)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-flex>
            <v-flex xs6 md6>
              <!--<v-text-field
                type="time"
                label="Hora Fin"
                v-model="form.horaFin"
                outlined
                dense
              ></v-text-field>-->
              <v-menu
                ref="menu"
                v-model="modalHoraFin"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="form.end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end"
                    label="Hora Fin"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    class="custom-placeholer-color"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="modalHoraFin"
                  v-model="form.end"
                  full-width
                  no-title
                  @click:minute="$refs.menu.save(form.end)"
                ></v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formIsValid"
            type="submit"
            :loading="isLoading"
            small
            class="context-button-style"
            rounded
          >
            <v-icon left> save </v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </div>
</template>
<script>
import apiServices from "/src/Services/apiServices";
import helperServices from "/src/Services/helperServices";

export default {
  data() {
    /* const defaultForm = Object.freeze({
      id: -1,
      patientSelected: null,
      appointmentDate: new Date().toISOString().substr(0, 10),
      horaInicio: null,
      horaFin: null,
      appointmentPlace: "",
    });*/

    return {
      //form: Object.assign({}, defaultForm),
      rules: {
        description: [(val) => (val || "").length > 0 || ""],
        // fechaCita: [(val) => (val || "").length > 0 || ""],
        // horaInicio: [(val) => (val || "").length > 0 || ""],
        // horaFin: [(val) => (val || "").length > 0 || ""],
      },
      // defaultForm,
      isLoading: false,
      isMobile: false,
      currentTheme: localStorage.getItem("theme-color"),
      menu2: false,
      modalHoraInicio: false,
      modalHoraFin: false,
      //horaInicio: this.form.horaInicio ? this.form.horaInicio : null,
      //horaFin: this.form.horaFin ? this.form.horaFin : null,
      //disabledDates: this.disabledDates, //["2024-09-25", "2024-09-30", "2024-09-27"],
      allowedDates: (val) => this.disabledDates.indexOf(val) === -1,
    };
  },
  props: {
    form: null,
    titleEvent: null,
    buttonTitle: null,
    isNewEvent: null,
    disabledDates: [],
  },
  computed: {
    formIsValid() {
      return (this.form.date && this.form.start && this.form.end);
    },
    computedDateFormatted() {
      return helperServices.DateService.formatDate(this.form.date);
    },
  },
  mounted() {},
  watch: {
    date() {
      this.form.formattedDate = helperServices.DateService.formatDate(
        this.form.date
      );
    },
  },
  methods: {
    AsignaHorario() {
      if (this.form.fullDay) {
        this.form.start = "06:00";
        this.form.end = "23:59";
      } else {
        this.form.start = "06:00";
        this.form.end = "23:59";
      }          
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async submit() {
      this.isLoading = true;
      let item = {
        id: this.form.id,
        date: this.form.date,
        fullDay: this.form.fullDay,
        start: this.form.start,
        end: this.form.end,
        statusColor: "red darken-4",
        description: this.form.description ? 'Horario bloqueado, No agendar' : this.form.description,
      };

      if (this.form.id == 0) {
        //Se inserta un nuevo registro
        apiServices.ScheduleDisabledService.InsertScheduleDisabled(item)
          .then(() => {
            //this.resetForm();
            this.closeWindow("insertEvent");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      } else {
        //edita un evento
        await apiServices.ScheduleDisabledService.UpdateScheduleDisabled(item)
          .then(() => {
            this.closeWindow("editEventSave");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },

    closeWindow(value) {
      if (value == "editEventSave") {
        let result = {
          isVisible: false,
          tipo: "editEventSave",
        };
        this.$emit("closeEditEvent", result);
      } else if (value == "insertEvent") {
        let result = {
          isVisible: false,
          tipo: "insertEvent",
        };
        this.$emit("closeEditEvent", result);
      } else if (value == "cerrarEditEvent") {
        let result = {
          isVisible: false,
          tipo: "cerrarEditEvent",
        };
        this.$emit("closeEditEvent", result);
      }
      //  this.resetForm();
    },
  },
  created() {
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>
