<template>
 <div
      class="context-card px-1 pa-1"
      v-show="role == 'Administrator' || role == 'Manager'"
    >
      <div class="context-title">
        <h5>Administra a tus asistentes</h5>
        <p>
          Crea cuentas para tus asistentes, todos podrán interactuar con tu
          información de pacientes y agenda, todos podrán ver la información
          actualizada.
        </p>
      </div>
      
      <v-row>
        <v-spacer></v-spacer>
        <template >
          <v-btn
            rounded
            dark
            @click="addItem()"
            class="context-button-style"
            small
          >
            <v-icon left>add</v-icon>
            Nuevo
          </v-btn>
        </template>
       <!-- <template v-else>
          
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="addItem()"
                class="context-icon-color"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Nuevo Usuario</span>
          </v-tooltip>
        </template>-->
      </v-row>
      <v-data-table
        :dark="isDark"
        :headers="headers"
        :items="users"
        :server-items-length="totalUsers"
        :options.sync="options"
        sort-by="userName"
        class="row-pointer context-table row-textcolor-table hoverTable"
        :search="search"
        @update:options="getUsers"
        fixed-header
      >
        <template v-slot:top>
          <v-toolbar flat class="context-toolbar">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Buscar usuario"
              single-line
              hide-details
              class="mt-5 mb-8 custom-placeholer-color"
              full-width
              dense
              flat
              outlined
              rounded
              placeholder="Escribe para filtrar la lista de usuarios"
              @change="getUsers"
            ></v-text-field>
            <v-btn
              rounded
              icon
              x-large
              class="context-icon-color mt-5 ml-3 mb-8"
              @click="getUsers"
            >
              <v-icon>search</v-icon>
            </v-btn>

            <!--<v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title>Eliminar</v-card-title>
                <v-card-subtitle
                  >Desea eliminar el usuario
                  {{ userSelected.userName }} ?</v-card-subtitle
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="error" @click="deleteItemConfirm"
                    >Eliminar</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>-->
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  class="context-icon-color mr-2"
                  @click.stop="editItem(item)"
                >
                  edit
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  medium
                  class="context-icon-color mr-2"
                  @click.stop="deleteItem(item)"
                >
                  delete
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:no-data>
          <span color="gray" class="text-body1 ml-5"
            >No hay asistentes registrados</span
          >
        </template>
      </v-data-table>
       <v-dialog
      v-model="AddUserDialog"
      width="500px"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <EditUser
        :userSelected="userSelected"
        :headerTitle="headerTitle"
        :buttonTitle="buttonTitle"
        @closeEditUser="closeWinEditUser"
      />
    </v-dialog>
    </div>
</template>

<script>
import apiServices from "/src/Services/apiServices";
import helperServices from "/src/Services/helperServices";
import EditUser from "/src/components/EditUser.vue";

export default {
  components: { EditUser },
  data() {
    const defaultForm = Object.freeze({
      id: -1,
      userName: "",
      Password: "",
      email: "",
      phone: "",
      specialty: "",
    });
    return {
      useruid: "",
      userSelected: Object.assign({}, defaultForm),
      id: -1,
      snackbar: false,
      messageSnackbar: "",
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
      countYearToDelete: 1,
      IsCleanEnabledBD: false,
      currentTheme: localStorage.getItem("theme-color"),
      role: localStorage.getItem("role"),
      AddUserDialog: false,
      headerTitle: "",
      buttonTitle: "",
      search: "",
      dialogDelete: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "userName",
          class: "context-header",
        },
        { text: "Email", value: "email", class: "context-header" },
        { text: "Teléfono", value: "phone", class: "context-header" },
        { text: "Cargo", value: "specialty", class: "context-header" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          class: "context-header",
        },
      ],
      users: [],
      options: {},
      totalUsers: 0,
      itemsPerPage: 0,
      page: 0,
      isDark: false,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getUsers;
      },
      deep: true,
    },
  },
  async created() {
    this.isMobile = helperServices.MobileService.isMobile();
    if (this.currentTheme == "theme-black") this.isDark = true;
    else this.isDark = false;
  },

  methods: {
    initialize() {},

    getUsers() {
      if (this.role != "Consumer") {
        const { page, itemsPerPage } = this.options;

        // sortBy,sortDesc
        var filter = {
          searchText: this.search,
          userUID: null,
          PageSize: itemsPerPage,
          PageNumber: page,
        };
        //this.itemsPerPage = filter.PageSize;
        //this.page = filter.page;
        this.loadingShow = true;
        apiServices.userService
          .GetConsumerUsersPagination(filter)
          .then((resp) => {
            this.users = resp.data.data;
            this.totalUsers = resp.data.meta.totalCount;
            //  this.currentPage = resp.data.meta.currentPage;
            this.loadingShow = false;
          })
          .catch((error) => {
            this.loadingShow = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    addItem() {
      this.headerTitle = "Agregar usuario asistente";
      this.buttonTitle = "Guardar";
      this.userSelected = Object.assign({}, this.defaultForm);
      this.userSelected.id = -1;
      this.AddUserDialog = true;
    },
    editItem(item) {
      this.headerTitle = "Editar usuario asistente";
      this.buttonTitle = "Actualizar";
      this.userSelected = item;
      this.AddUserDialog = true;
    },
    deleteItem(item) {
      this.userSelected = item;

      //this.dialogDelete = true;
      this.$fire({
        title: "Desea eliminar el usuario asistente?",
        text: item.userName,
        type: "question",

        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Usuario asistente eliminado correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },
    deleteItemConfirm() {
      if (this.userSelected) {
        console.log("Id Usuario:" + this.userSelected);
        apiServices.userService
          .DeleteConsumerUser(this.userSelected.id)
          .then(() => {
            this.search = "";
            this.getUsers();
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeWinEditUser(result) {
      if (result.tipo == "cerrar") {
        this.AddUserDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddUserDialog = result.isVisible;
        //this.snackbar = true;
        // this.messageSnackbar = "Paciente creado correctamente.";

        this.$fire({
          position: "top-end",
          title: "Usuario asistente creado correctamente",
          // text: "Operación exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getUsers();
        //  this.options="getPatients";
        // this.totalPatients += 1;
        // this.showSnackbar = true;
      } else if (result.tipo == "editar") {
        this.AddUserDialog = result.isVisible;
        // this.snackbar = true;
        // this.messageSnackbar = "Paciente modificado correctamente.";
        this.getUsers();
        //https://www.npmjs.com/package/vue-simple-alert
        this.$fire({
          position: "top-end",
          title: "Usuario asistente modificado correctamente",
          // text: "Edición exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          //grow:'row',
          //width: 300,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });

        //this.options = "getPatients";
        //console.log(result)
        //const indexOfItemInArray = this.patients.findIndex(q => q.id === result.item.id);
        //this.patients.splice(indexOfItemInArray, 1, result.item);
        // this.options.handler.getPatients;
        // this.showSnackbar = true;
      }
    },
  },
};
</script>
<style></style>
